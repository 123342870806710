import axios from "axios";
import PortalCooperadoUtils from "../utilities/PortalCooperadoUtils";

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/banner`;

class BannerService {
  carregarBanners() {
    return axios.get(`${API_BASE_URL}${RESOURCE_V1}/todos`);
  }

  atualizar(banners) {
    return axios.put(`${API_BASE_URL}${RESOURCE_V1}/atualizar`, banners);
  }

  excluir(idBanner) {
    return axios.delete(`${API_BASE_URL}${RESOURCE_V1}/delete/${idBanner}`);
  }

  criar(banner) {
    delete banner.id;
    return axios.post(`${API_BASE_URL}${RESOURCE_V1}/create`, banner);
  }
}

export default new BannerService();
