<template>
  <div class="card">
    <Toast />
    <h5>{{ isEditing ? 'Editar Banner' : 'Novo Banner' }}</h5>

    <Dialog v-model:visible="dialogVisible" header="Informações" :modal="true" :style="{ width: '400px' }">
      <ul>
        <li v-for="path in paths" :key="path">{{ path }}</li>
      </ul>
      <template #footer>
        <Button label="Fechar" icon="pi pi-times" @click="dialogVisible = false" class="p-button-text" />
      </template>
    </Dialog>
    <div>
      <div class="form-group mt-5">
        <label class="font-bold mb-3">Título <b class="required">*</b></label>
        <InputText class="mt-3" v-model="banner.titulo" style="width: 100%"
          placeholder="Insira o titulo que do banner que aparecerá no aplicativo" />
      </div>
      <div class="form-group mt-5">
        <label class="font-bold mb-3">Descrição <b class="required">*</b></label>
        <Textarea class="mt-3" v-model="banner.descricao" rows="4" style="width: 100%"
          placeholder="Insira a descrição que do banner que aparecerá no aplicativo" />
      </div>

      <Button label="Pressione para visualizar os paths disponíveis" icon="pi pi-info-circle"
        @click="dialogVisible = true" class="p-button-info mt-5" />

      <div class="form-group mt-3">
        <label class="font-bold">Path <b class="required">*</b></label>
        <InputText class="mt-3" v-model="banner.path" style="width: 100%"
          placeholder="Insira um link ou path para redirecionar o usuário ao clicar no banner" />
      </div>
      <div class="form-group mt-5">
        <Message severity="info" :closable="false">
          A imagem deve ser até 1mb e corresponder a uma das seguintes resoluções:
          1080x820, 960x720, 854x640, 800x600, 720x540, 640x480, 576x432, 540x405, 480x360, 426x320, 400x300.
        </Message>
        <label class="font-bold">Imagem <b class="required">*</b></label>
        <InputText class="mt-3" type="file" accept="image/*" @change="onImageSelected" style="width: 100%" />

        <div v-if="banner.imagem" class="mt-5">

          <div class="image-editor">
            <div class="selected-image">
              <label class="font-bold mb-3">Imagem selecionada:</label>
              <img :src="getImageSrc(banner.imagem)" alt="Preview" style="max-width: 150px; max-height: 150px;" />
            </div>

            <div class="selected-image">
              <label class="font-bold mb-3">Imagem reproduzida no app:</label>
              <div class="image-preview" :style="{
                backgroundImage: 'url(' + imageUrl + ')',
                backgroundSize: zoomLevel + '%'
              }">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-5">
        <label class="font-bold mb-3 ">Texto do Botão <b class="required">*</b></label>
        <InputText class="mt-3" v-model="banner.textoBotao" style="width: 100%"
          placeholder="Insira o texto que aparecerá no botão do banner" />
      </div>
      <div class="form-group">
        <Button label=" Salvar" icon="pi pi-save" class="p-button-success mt-4" @click="salvar"
          :disabled="!validarCampos(banner)" />
        <Button label="Cancelar" icon="pi pi-times" class="p-button-danger mt-4 ml-4" @click="cancelar" />
      </div>
    </div>
  </div>
</template>

<script>
import BannerService from '../../service/BannerService';

const resolutionsMap = {
  '1080x820': 200,
  '960x720': 200,
  '854x640': 200,
  '800x600': 200,
  '720x540': 200,
  '640x480': 210,
  '576x432': 210,
  '540x405': 210,
  '480x360': 210,
  '426x320': 210,
  '400x300': 220,
};

export default {
  name: 'editBanner',
  data() {
    return {
      dialogVisible: false,
      paths: [
        '/about-us',
        '/settings',
        '/privacy-policy',
        '/terms-of-use',
        '/weather-forecast',
        '/manage-shortcuts',
        '/messages',
        '/comercial-transactions',
        '/contracts',
        '/supplies',
        '/fiscal-notes',
        '/prices',
        '/production',
        '/finances',
        '/bills-to-pay',
        '/bills-to-receive',
        '/remains',
        '/share-capital',
        '/income-report',
        '/agricultural-dossier',
        '/cooperator',
        '/classifieds',
        '/advertise-item',
        '/personal-data',
        '/health-plan',
        '/registered-properties',
        '/news',
        '/our-units',
        '/events',
        '/services',
        '/faq',
        '/cereals-sale-tabs',
        '/signing-orders'
      ],
      banner: {
        titulo: '',
        descricao: '',
        path: '',
        imagem: '',
        textoBotao: '',
        nomeImagem: `${new Date().getTime()}-${Math.random().toString(36).substring(7)}`,
        ativo: true
      },
      loading: false,
      isEditing: false,
      imageUrl: '',
      zoomLevel: 200,
    };
  },
  created() {
    this.isEditing = window.history.state.data?.id > 0;
    if (this.isEditing) {
      this.imageUrl = this.getImageSrc(window.history.state.data.imagem);
      const img = new Image();
      img.src = this.imageUrl;
      img.onload = () => {
        const imageWidth = img.width;
        const imageHeight = img.height;
        const resolution = `${imageWidth}x${imageHeight}`;
        this.zoomLevel = resolutionsMap[resolution];
      };

      this.banner = window.history.state.data;
    }
  },
  methods: {
    getImageSrc(base64) {
      return `data:image/jpeg;base64,${base64}`;
    },

    onImageSelected(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      const img = new Image();
      reader.onload = (e) => {
        const base64String = e.target.result.split(',')[1];

        img.src = e.target.result;
        img.onload = () => {
          const imageWidth = img.width;
          const imageHeight = img.height;

          const resolution = `${imageWidth}x${imageHeight}`;


          if (resolutionsMap[resolution]) {
            this.zoomLevel = resolutionsMap[resolution];
            this.banner.imagem = base64String;
            this.imageUrl = `data:image/jpeg;base64,${base64String}`;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Resolução não suportada",
              detail: `A imagem deve ter uma das seguintes resoluções: ${Object.keys(resolutionsMap).join(', ')}`,
              life: 5000
            });
            this.banner.imagem = '';
            this.imageUrl = '';
          }
        };
      };
      reader.readAsDataURL(file);
    },
    salvar() {
      if (!this.validarCampos(this.banner)) return;

      this.loading = true;

      if (!this.isEditing) {
        BannerService.criar(this.banner)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Banner criado com sucesso!",
              life: 3000
            });
            this.$router.push('/banners');
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Falha",
              detail: "Falha ao criar o banner!",
              life: 5000
            });
          })
          .finally(() => {
            this.loading = false;
          });
        return;
      }

      BannerService.atualizar(this.banner)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Banner atualizado com sucesso!",
            life: 3000
          });
          this.$router.push('/banners');
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Falha",
            detail: "Falha ao atualizar o banner!",
            life: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelar() {
      this.$router.push('/banners');
    },
    validarCampos(banner) {
      if (this.loading) {
        return false;
      }
      if (!banner.titulo || !banner.descricao || !banner.path || !banner.imagem || !banner.textoBotao) {
        return false;
      }

      return true;
    },
  }
};
</script>

<style scoped>
.required {
  color: red;
}


.selected-image {
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  align-items: center;
  justify-content: center;
}

.image-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.image-preview {
  height: 150px;
  width: 105px;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}
</style>